<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="8">
                <el-card shadow="hover" class="mgb20">
                    <div class="user-info">
                        <img :src="userInfo.pic" class="user-avator" alt="">
                        <div class="user-info-cont">
                            <div class="user-info-name">{{userInfo.username}}</div>
                            <div>{{userInfo.roleName}}</div>
                        </div>
                    </div>
                    <div class="user-info-list">登录时间：<span>{{userInfo.datetime}}</span></div>
                    <!--<div class="user-info-list">上次登录地点：<span>东莞</span></div>-->
                </el-card>
                <el-card shadow="hover" v-if="isShowSelect">
                    <el-col :span="24" class="mgb20">
                        <el-date-picker
                                v-model="value2"
                                type="datetimerange"
                                format="yyyy-MM-dd HH:mm:ss"
                                :picker-options="pickerOptions"
                                @change="changeTime"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                align="right">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="24" class="mgb20">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                            <div class="grid-content grid-con-2">
                                <i class="el-icon-lx-notice grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{temp.orderCount}}</div>
                                    <div>订单量</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="24" class="mgb20">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                            <div class="grid-content grid-con-3">
                                <i class="el-icon-lx-goods grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{temp.sumPrice}}</div>
                                    <div>订单总金额</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="24" class="mgb20">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                            <div class="grid-content grid-con-1">
                                <i class="el-icon-lx-people grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{temp.addUserCount}}</div>
                                    <div>新增用户</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
					
                    <el-col :span="24" class="mgb20">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                            <div class="grid-content grid-con-2">
                                <i class="el-icon-lx-goods grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{temp.sumPrice2}}</div>
                                    <div>核销金额</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
					
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card shadow="hover" v-if="isShowSelect">
                    <el-timeline>
                        <el-timeline-item v-for="(item, index) in temp.record" color="#0BBD87" :timestamp="item.datetime" placement="top">
                            <el-card>
                                <h4><span style="color: red; font-weight: bold">{{item.account}}</span>{{item.operation}}</h4>
                                <p style="line-height: 35px">操作时间：{{item.datetime}}</p>
                                <p style="font-size: 10px; color: #999999;">——{{item.remarks}}</p>
                            </el-card>
                        </el-timeline-item>

                    </el-timeline>
                </el-card>
                <!--<el-row :gutter="20" class="mgb20">-->



                <!--</el-row>-->
                <!--<el-card shadow="hover" style="height:403px;">-->
                    <!--<div slot="header" class="clearfix">-->
                        <!--<span>待办事项</span>-->
                        <!--<el-button style="float: right; padding: 3px 0" type="text">添加</el-button>-->
                    <!--</div>-->
                    <!--<el-table :data="todoList" :show-header="false" height="304" style="width: 100%;font-size:14px;">-->
                        <!--<el-table-column width="40">-->
                            <!--<template slot-scope="scope">-->
                                <!--<el-checkbox v-model="scope.row.status"></el-checkbox>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column>-->
                            <!--<template slot-scope="scope">-->
                                <!--<div class="todo-item" :class="{'todo-item-del': scope.row.status}">{{scope.row.title}}</div>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column width="60">-->
                            <!--<template slot-scope="scope">-->
                                <!--<i class="el-icon-edit"></i>-->
                                <!--<i class="el-icon-delete"></i>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                    <!--</el-table>-->
                <!--</el-card>-->
            </el-col>
        </el-row>
        <!--<el-row :gutter="20">-->
            <!--<el-col :span="12">-->
                <!--<el-card shadow="hover">-->
                    <!--<schart ref="bar" class="schart" canvasId="bar" :data="data" type="bar" :options="options"></schart>-->
                <!--</el-card>-->
            <!--</el-col>-->
            <!--<el-col :span="12">-->
                <!--<el-card shadow="hover">-->
                    <!--<schart ref="line" class="schart" canvasId="line" :data="data" type="line" :options="options2"></schart>-->
                <!--</el-card>-->
            <!--</el-col>-->
        <!--</el-row>-->
    </div>
</template>

<script>
    import Schart from 'vue-schart';
    import bus from '../common/bus';
    export default {
        name: 'dashboard',
        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: [new Date(2020, 3, 12, 0, 0, 0), new Date(2020, 3, 12, 23, 59, 59)],
                temp:{
                    addUserCount: 0,
                    orderCount: 0,
                    sumPrice: 0,
                },
                userInfo:JSON.parse(localStorage.getItem('userInfo')),
                isShowSelect: false,
                todoList: [{
                        title: '今天要修复100个bug',
                        status: false,
                    },
                    {
                        title: '今天要修复100个bug',
                        status: false,
                    },
                    {
                        title: '今天要写100行代码加几个bug吧',
                        status: false,
                    }, {
                        title: '今天要修复100个bug',
                        status: false,
                    },
                    {
                        title: '今天要修复100个bug',
                        status: true,
                    },
                    {
                        title: '今天要写100行代码加几个bug吧',
                        status: true,
                    }
                ],
                data: [{
                        name: '2018/09/04',
                        value: 1083
                    },
                    {
                        name: '2018/09/05',
                        value: 941
                    },
                    {
                        name: '2018/09/06',
                        value: 1139
                    },
                    {
                        name: '2018/09/07',
                        value: 816
                    },
                    {
                        name: '2018/09/08',
                        value: 327
                    },
                    {
                        name: '2018/09/09',
                        value: 228
                    },
                    {
                        name: '2018/09/10',
                        value: 1065
                    }
                ],
                options: {
                    title: '最近七天每天的用户访问量',
                    showValue: false,
                    fillColor: 'rgb(45, 140, 240)',
                    bottomPadding: 30,
                    topPadding: 30
                },
                options2: {
                    title: '最近七天用户访问趋势',
                    fillColor: '#FC6FA1',
                    axisColor: '#008ACD',
                    contentColor: '#EEEEEE',
                    bgColor: '#F5F8FD',
                    bottomPadding: 30,
                    topPadding: 30
                }
            }
        },
        components: {
            Schart
        },
        computed: {

        },
        created(){
            const userInfo=JSON.parse(localStorage.getItem('userInfo'));
            if(userInfo.cid){
                this.isShowSelect=false;
            }else{
                this.isShowSelect=true;
            }
            // console.log(userInfo);
            //时间选择器默认昨天日期时间
            let aDate = new Date();
            let tempArr=[
                new Date(aDate.getFullYear(), aDate.getMonth(), aDate.getDate()-1, 0, 0, 0),
                new Date(aDate.getFullYear(), aDate.getMonth(), aDate.getDate()-1, 23, 59, 59)
            ];
            this.value2=tempArr;
            this.getDefaultData();
            this.handleListener();
            this.changeDate();
        },


        activated(){
            this.handleListener();
        },
        deactivated(){
            window.removeEventListener('resize', this.renderChart);
            bus.$off('collapse', this.handleBus);
        },
        methods: {
            changeTime(){
                this.getDefaultData();
            },
            getDefaultData(){
                //请求首页数据
                let params={
                    startTime: this.value2[0],
                    endTime: this.value2[1]
                };
                this.$api.post('Index/index', this.$qs.stringify(params),  res => {
                    this.temp=res.data;
                },err => {

                });
            },
            changeDate(){
                const now = new Date().getTime();
                this.data.forEach((item, index) => {
                    const date = new Date(now - (6 - index) * 86400000);
                    item.name = `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`
                })
            },
            handleListener(){
                bus.$on('collapse', this.handleBus);
                // 调用renderChart方法对图表进行重新渲染
                window.addEventListener('resize', this.renderChart)
            },
            handleBus(msg){
                setTimeout(() => {
                    this.renderChart()
                }, 300);
            },
            renderChart(){
                this.$refs.bar.renderChart();
                this.$refs.line.renderChart();
            }
        }
    }

</script>


<style scoped>
    .el-row {
        margin-bottom: 20px;
    }

    .grid-content {
        display: flex;
        align-items: center;
        height: 100px;
    }

    .grid-cont-right {
        flex: 1;
        text-align: center;
        font-size: 14px;
        color: #999;
    }

    .grid-num {
        font-size: 30px;
        font-weight: bold;
    }

    .grid-con-icon {
        font-size: 50px;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #fff;
    }

    .grid-con-1 .grid-con-icon {
        background: rgb(45, 140, 240);
    }

    .grid-con-1 .grid-num {
        color: rgb(45, 140, 240);
    }

    .grid-con-2 .grid-con-icon {
        background: rgb(100, 213, 114);
    }

    .grid-con-2 .grid-num {
        color: rgb(45, 140, 240);
    }

    .grid-con-3 .grid-con-icon {
        background: rgb(242, 94, 67);
    }

    .grid-con-3 .grid-num {
        color: rgb(242, 94, 67);
    }

    .user-info {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 2px solid #ccc;
        margin-bottom: 20px;
    }

    .user-avator {
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }

    .user-info-cont {
        padding-left: 50px;
        flex: 1;
        font-size: 14px;
        color: #999;
    }

    .user-info-cont div:first-child {
        font-size: 30px;
        color: #222;
    }

    .user-info-list {
        font-size: 14px;
        color: #999;
        line-height: 25px;
    }

    .user-info-list span {
        margin-left: 70px;
    }

    .mgb20 {
        margin-bottom: 20px;
    }

    .todo-item {
        font-size: 14px;
    }

    .todo-item-del {
        text-decoration: line-through;
        color: #999;
    }

    .schart {
        width: 100%;
        height: 300px;
    }

</style>
